.App {
	text-align: center;
}

.App-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	background-color: #282C34;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + .5vmin);
	color: #FFF;
	z-index: 3;
}

.App-header:hover {
	background-color: #FEDA32;
}